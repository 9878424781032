<template>
  <el-dialog class="addUser-dialog" :close-on-click-modal="false" :visible.sync="show"
             :title="`${preTitle}用户${title}`"
             v-loading="loadData.loading" @close="cancel" width="820px">
    <el-form ref="form" :model="formData" :rules="rules" label-width="100px">
      <el-form-item v-if="!id" label="账号类型" prop="roleType">
        <el-radio-group v-model="formData.roleType">
          <el-radio-button :label="1">教师账号</el-radio-button>
          <el-radio-button :label="2">其他</el-radio-button>
        </el-radio-group>
      </el-form-item>
      <el-row>
        <el-col :span="12">
          <el-form-item label="姓名" prop="realName">
            <el-input v-model.trim="formData.realName" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="工号" prop="username">
            <el-input v-model.trim="formData.username" />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="职工类别" prop="teacherType">
            <el-select v-model="formData.teacherType" placeholder="请选择">
              <el-option v-for="item in teacherTypeList" :key="item.paramValue" :label="item.paramValue"
                         :value="item.paramValue">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="入职时间" prop="entryTime">
            <el-date-picker type="date" placeholder="请选择" v-model="formData.entryTime" style="width: 100%"
                            value-format="yyyy-MM-dd" />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="部门">
            <el-select v-model="formData.deptId" ref="deptRef" filterable clearable
                       @change="handleDeptId($event, 'dept')">
              <el-option v-for="{ deptName, id } in departmentList" :label="deptName" :value="id" :key="id" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="院系">
            <el-select ref="collegeRef" v-model="formData.collegeId" filterable clearable
                       @change="handleDeptId($event, 'college')">
              <el-option v-for="{ collegeName, id } in collegeList" :label="collegeName" :value="id" :key="id" />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <div class="tip">
        新用户在保存后才能通过编辑，设置为心理教师
      </div>
      <el-row>
        <el-col :span="12">
          <el-form-item label="是否心理教师">
            <el-switch v-model="formData.psychologyFlag"
                       :disabled="!formData.id"
                       :active-value="1"
                       :inactive-value="0"
                       :active-text="'是'"
                       :inactive-text="'否'"
                       @change="handlePsychologyFlag"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item v-if="isRoleEditor" label="角色">
        <tool-tips-group is-flex
                         tips-content="<b>同一账号无法同时分配多个请假审批角色。</b><br/>请假审批角色包括：辅导员、学生处、副院长、校领导等，<br/>更多详情请到”系统设置-角色管理“模块查看或编辑。">
          <el-select ref="roleSelectRef" v-model="formData.roleIds" multiple filterable placeholder="请选择角色">
            <el-option v-for="{ roleName, id } in roleList" :label="roleName" :value="id" :key="id" />
          </el-select>
        </tool-tips-group>
      </el-form-item>
      <el-form-item label="备注" prop="remarks">
        <el-input v-model.trim="formData.remarks" />
      </el-form-item>
      <el-divider content-position="left">用户信息</el-divider>
      <el-row>
        <el-col :span="12">
          <el-form-item label="用户照片" prop="realImg">
            <single-img-uploader img-fit="contain" :url.sync="formData.realImg" height="164px" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="性别" prop="sexType">
            <el-radio-group v-model="formData.sexType" placeholder="请选择性别">
              <el-radio v-for="{ name, type } in $store.state.systemParam.sexType" :label="type" :key="type">{{ name }}
              </el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="身份证号码" prop="cardNum">
            <el-input v-model.trim="formData.cardNum" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="手机号码" prop="phone">
            <el-input v-model.trim="formData.phone"  maxlength="11"/>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <template #footer>
      <cancel-popover ref="cancelRef" :disabled.sync="loadData.loading" :update-flag="updateFlag" />
      <el-button type="primary" @click="handleSave">确 定</el-button>
    </template>
  </el-dialog>
</template>

<script>
import { addUser, getUserInfoById } from '@/api/system/user-api'
import dialog from '@/vue/mixins/dialog'
import { validateIdNumber, validatePhone } from '@/utils/form-validate'
import { listRoleApi } from '@/api/system/role-api'
import { getParamsList } from '@/api/sysParams.js'
import CampusSchMajorClasses from '@/vue/mixins/campusSchMajorClasses'
import { editPsychologyTeacherApi } from '@/api/student-manage/psychology-teacher-api'

export default {
  name: 'userDialog',
  mixins: [dialog, CampusSchMajorClasses],
  props: {
    isRoleEditor: Boolean
  },
  data() {
    return {
      formData: {
        id: '',
        roleType: 1, // 1- 教师账号 带上教职工的角色 2-其他，不带教职工的角色
        realName: '', // 姓名
        username: '', // 账号
        password: '',
        phone: '',
        cardNum: '',
        remarks: '',
        userType: 3, // 用户类型1-教师,2-学生,3-后台管理员,一般固定
        teacherType: '',
        entryTime: '', // 入职时间
        collegeId: this.$store.state.login.userInfo.collegeId || '',
        deptId: '',
        realImg: '',
        sexType: null,
        psychologyFlag: 0, // 是否心理教师
        roleIds: []
      },
      roleList: [], // 全部角色列表
      departmentList: [],
      teacherTypeList: [], // 职工类型列表
      rules: {
        realName: [{
          required: true,
          message: '请输入姓名',
          trigger: 'blur'
        },
          this.$store.state.commonValidate.limit20WordsObj],
        teacherType: [{
          required: true,
          message: '请选择职工类别',
          trigger: 'blur'
        }],
        cardNum: [
          {
            required: true,
            message: '请输入身份证号',
            trigger: 'blur'
          },
          {
            validator: validateIdNumber,
            trigger: 'blur'
          }],
        phone: [
          {
            required: true,
            message: '请输入手机号',
            trigger: 'blur'
          },
          {
            validator: validatePhone,
            trigger: 'blur'
          }
        ],
        username: [
          {
            required: true,
            message: '请输入用户名',
            trigger: 'blur'
          },
          {
            min: 2,
            max: 20,
            message: '长度在 2 到 20 个字符',
            trigger: 'blur'
          }
        ]
      }
    }
  },
  async mounted() {
    await Promise.all([
      this.getCollegeList(),
      this.getDepartmentList(),
      this.getRoleList(),
      this.getTeacherType()
    ])
    this.$nextTick(() => {
      if (this.id) {
        this.getUserInfoById(this.id)
      }
    })
  },
  methods: {
    // 根据id获取用户信息
    getUserInfoById(id) {
      this.getDataById(id, getUserInfoById)
    },
    // 获取部门
    async getDepartmentList() {
      try {
        const res = await this.$http.getDepartmentListQuery({})
        this.departmentList = res.data
      } catch (e) {
      }
    },
    // 获取职工类型
    async getTeacherType() {
      try {
        const res = await getParamsList({ keyword: '教师类型' })
        this.teacherTypeList = res.data.list
      } catch (e) {
        console.error(e)
      }
    },
    // 获取全部角色列表
    async getRoleList() {
      try {
        const res = await listRoleApi()
        this.roleList = res.data
      } catch (e) {
      }
    },
    // 根据 获取的学院自动填写 部门，或者根据部门获取学院 由于部门跟院系 id不一致，因此只能根据名字判断 而不是 id
    handleDeptId(val, type) {
      this.$nextTick(() => {
        if (val) {
          switch (type) {
            case 'college':
              this.formData.deptId = this.departmentList.find(({ deptName }) => this.$refs.collegeRef.selectedLabel === deptName)?.id
              break
            case 'dept':
              this.formData.collegeId = this.collegeList.find(({ collegeName }) => this.$refs.deptRef.selectedLabel === collegeName)?.id
              break
          }
        }
      })
    },
    handlePsychologyFlag(val) {
      this.$confirm('是否确认设置为心理咨询教师？').then(async () => {
        try {
          await editPsychologyTeacherApi({
            id: this.formData.id,
            psychologyFlag: val
          })
          this.$message.success(`${val ? '设置' : '取消'}成功！`)
        } catch (e) {
          this.formData.psychologyFlag = val ? 0 : 1
        }
      }).catch(() => {
        this.formData.psychologyFlag = val ? 0 : 1
      })
    },
    handleSave() {
      if (this.$refs?.roleSelectRef?.selected?.length) {
        const _selectRoleList = this.$refs.roleSelectRef.selected.filter(v => v.label.includes('请假审批角色'))
        if (_selectRoleList.length > 1) {
          this.$message.error(`以下【请假审批角色】不可分配给同一个账号：${_selectRoleList.map(({ label }) => label)}!`)
          return
        }
      }
      if (!this.formData.password) {
        this.formData.password = this.$md5('nfxy14265')
      }
      const _successMsg = this.id ? '用户信息修改成功!' : '添加成功，用户的初始密码为：nfxy14265，为保障账号安全，请尽快修改密码！'
      this.save('用户账号', addUser, _successMsg)
    }
  }
}
</script>
