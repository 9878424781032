<template>
  <div class="addTeacher-dialog">
    <base-dialog
      :model="addData.isOpen"
      @close="closeDialogHandle"
      :title="addData.title"
    >
      <template #body>
        <el-form
          ref="form"
          :model="formData"
          :rules="rules"
          label-width="100px"
        >
        <el-form-item label="所在校区" prop="campusId">
            <el-select
              v-model="formData.campusId"
              @change="changeCampus"
            >
              <span v-for="(item, index) in campusList" :key="index">
                <el-option
                  :label="item.campusName"
                  :value="item.id"
                  :key="index"
                ></el-option>
              </span>
            </el-select>
          </el-form-item>
          <el-form-item label="所属院系">
            <el-select
              v-model="formData.collegeId"
            >
              <span v-for="(item, index) in collegeList" :key="index">
                <el-option
                  :label="item.collegeName"
                  :value="item.id"
                  :key="index"
                ></el-option>
              </span>
            </el-select>
          </el-form-item>
          <el-form-item label="教师名称" prop="teacherName">
            <el-input v-model.trim="formData.teacherName"></el-input>
          </el-form-item>
          <el-form-item label="性别">
            <el-select v-model="formData.sexType">
              <span v-for="(item, index) in sexType" :key="index">
                <el-option
                  :label="item.name"
                  :value="item.type"
                  :key="index"
                ></el-option>
              </span>
            </el-select>
          </el-form-item>
          <el-form-item label="工作职务">
            <el-input v-model.trim="formData.job"></el-input>
          </el-form-item>
          <el-form-item label="手机号码">
            <el-input type="number" v-model.trim="formData.phone" maxlength="11"></el-input>
          </el-form-item>
          <el-form-item label="是否为辅导员">
            <el-radio v-model="formData.counsellorFlag" :label="Number(0)"
              >否</el-radio
            >
            <el-radio v-model="formData.counsellorFlag" :label="Number(1)"
              >是</el-radio
            >
          </el-form-item>
          <el-form-item label="备注" prop="remarks">
            <el-input v-model.trim="formData.remarks"></el-input>
          </el-form-item>
        </el-form>
      </template>
      <template #footer>
        <el-button @click="addData.isOpen = false">取 消</el-button>
        <el-button type="primary" @click="addTeacherHandle">确 定</el-button>
      </template>
    </base-dialog>
  </div>
</template>

<script>
import { addTeacher, getTeacherById } from '@/api/teacher'

export default {
  props: {
    addData: {
      type: Object
    }
  },
  data () {
    return {
      formData: {
        campusId: '', // 校区
        collegeId: '', // 院系
        teacherName: '', // 姓名
        sexType: 1, // 1——男，2——女
        phone: '', // 手机号码
        counsellorFlag: 0, // 是否为辅导员
        job: '', // 职务
        userId: '', // 用户id
        remarks: ''
      },
      campusList: [],
      collegeList: [],
      sexType: [],
      rules: {
        teacherName: [{ required: true, message: '请输入教师名称', trigger: 'blur' }]
      }
    }
  },
  watch: {
    'addData.isOpen': function (isOpen) {
      if (isOpen) {
        this.sexType = this.$store.state.systemParam.sexType
        this.getCampusList()
      }
      if (isOpen && this.addData.type === 'edit') {
        this.getTeacherById()
      }
      if (!isOpen) {
        this.resetFormData()
      }
    }
  },
  methods: {
    // 根据id获取教师信息
    getTeacherById () {
      getTeacherById(this.addData.id).then(res => {
        Object.keys(this.formData).forEach((item) => {
          this.formData[item] = res.data[item]
        })
        this.formData.id = this.addData.id
        this.getCollegeList(this.formData.campusId)
      })
    },
    // 添加教师
    addTeacherHandle () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          addTeacher(this.formData).then((res) => {
            this.$refs.form.resetFields()
            this.$message({
              message: '添加成功',
              type: 'success'
            })
            this.$emit('successHandle')
            this.closeDialogHandle()
          })
        }
      })
    },
    // 获取校区
    getCampusList () {
      this.$http.getCampusListQuery({}).then(res => {
        this.campusList = res.data
      })
    },
    changeCampus (campusId) {
      this.formData.collegeId = ''
      this.getCollegeList(campusId)
    },
    // 获取院系
    getCollegeList (campusId) {
      this.$http.getCollegeListQuery({
        campusId
      }).then((res) => {
        this.collegeList = res.data
      })
    },
    // 重置搜索表单
    resetFormData () {
      this.formData = {
        campusId: '', // 校区
        collegeId: '', // 院系
        teacherName: '', // 姓名
        sexType: 1, // 1——男，2——女
        phone: '', // 手机号码
        counsellorFlag: 0, // 是否为辅导员
        job: '', // 职务
        userId: '', // 用户id
        remarks: ''
      }
    },
    // 关闭弹窗
    closeDialogHandle () {
      this.addData.isOpen = false
    }
  }
}
</script>
<style lang="scss" scoped>

</style>
