import request from '@/service/request'
// 自定义非全日制缴费

// 自定义非全日制缴费 page
export const pagePartTimePaymentApi = (params) => {
  return request.post('/stu/stuInfoPartTimeCustom/page', params)
}

// 自定义非全日制缴费 根据id获取
export const getPartTimePaymentByIdApi = (id) => {
  return request.post(`/stu/stuInfoPartTimeCustom/getById/${id}`)
}

// 自定义非全日制缴费 编辑
export const savePartTimePaymentApi = (params) => {
  return request.post('/stu/stuInfoPartTimeCustom/save', params)
}

// 自定义非全日制缴费 根据id删除
export const delPartTimePaymentByIdApi = (id) => {
  return request.post(`/stu/stuInfoPartTimeCustom/del/${id}`)
}
