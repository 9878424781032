import request from '@/service/request'

// 获取录取通知列表
export const pageAdmissionLetterApi = (params) => {
  return request.post('/stu/admission/page', params)
}

// 删除录取通知
export const deleteAdmissionLetter = (id) => {
  return request.post('/stu/admission/delete/' + id)
}

// 根据【id】获取录取通知信息
export const getAdmissionLetterById = (id) => {
  return request.post('/stu/admission/getById/' + id)
}

// 录取通知书 信息 保存
export const saveAdmissionLetterApi = (params) => {
  return request.post('/stu/admission/save', params)
}
