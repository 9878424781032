<template>
  <el-dialog
    class="part-time-student-dialog"
    :title="`${preTitle}继续教育学院学生${title}`"
    :visible.sync="show"
    :close-on-click-modal="false"
    width="620px"
    :before-close="cancel">
    <el-form ref="form" :model="formData" :rules="rules" label-width="100px">
      <el-row>
        <div v-permission="['partTimeStu:edit:base']">
          <el-form-item label="姓名" prop="stuName">
            <el-input v-model.trim="formData.stuName" />
          </el-form-item>
          <el-form-item label="证件类型" prop="idCardType">
            <el-radio-group v-model="formData.idCardType" @change="handleIdType">
              <el-radio-button :label="1">内地身份证</el-radio-button>
              <el-radio-button :label="0">港澳身份证</el-radio-button>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="身份证号" prop="idNumber">
            <el-input v-model.trim="formData.idNumber"  maxlength="18"/>
          </el-form-item>
          <el-form-item label="考生号" prop="examineCode">
            <el-input v-model.trim="formData.examineCode"  maxlength="15"/>
          </el-form-item>
          <el-form-item label="联系电话" prop="fixedPhone">
            <el-input v-model.trim="formData.fixedPhone"  maxlength="11"/>
          </el-form-item>
          <el-form-item label="年级" prop="grade">
            <el-select v-model="formData.grade">
              <el-option
                v-for="year in gradeList"
                :label="year"
                :value="year"
                :key="year" />
            </el-select>
          </el-form-item>
        </div>
        <div v-permission="['partTimeStu:edit:major']">
          <el-form-item label="专业" prop="majorId">
            <el-select v-model="formData.majorId" filterable>
              <el-option
                v-for="{majorName,id} in majorList"
                :label="majorName"
                :value="id"
                :key="id" />
            </el-select>
          </el-form-item>
        </div>
        <div v-permission="['partTimeStu:edit:payStatus']">
          <el-form-item label="一学年学费" prop="payFlagYearOne">
            <el-switch
              v-model="formData.payFlagYearOne"
              :active-value="1"
              :inactive-value="0"
              active-text="已缴费"
              inactive-text="未缴费" />
          </el-form-item>
          <el-form-item label="二学年学费" prop="payFlagYearTwo">
            <el-switch
              :active-value="1"
              :inactive-value="0"
              v-model="formData.payFlagYearTwo"
              active-text="已缴费"
              inactive-text="未缴费" />
          </el-form-item>
          <el-form-item label="三学年学费" prop="payFlagYearThree">
            <el-switch
              :active-value="1"
              :inactive-value="0"
              v-model="formData.payFlagYearThree"
              active-text="已缴费"
              inactive-text="未缴费" />
          </el-form-item>
        </div>
      </el-row>
      <el-form-item label="备注" prop="remarks">
        <el-input v-model.trim="formData.remarks" />
      </el-form-item>
    </el-form>
    <template #footer>
      <cancel-popover ref="cancelRef" :disabled.sync="loadData.loading" :update-flag="updateFlag" />
      <el-button type="primary" @click="save('全日制学生',addStudentPartTime)">确 定</el-button>
    </template>
  </el-dialog>
</template>

<script>
import {
  policyStatusList,
  nationList
} from '@/views/pages/diagnosis-and-improvement/infoCollect/infoCollect/components/options.js'
import { addStudentPartTime, getPartTimeMajors, getStudentPartTimeById } from '@/api/studentPartTime'
import dialog from '@/vue/mixins/dialog'
import { validateIdNumber, validateTwoDecimalPlaces } from '@/utils/form-validate'

export default {
  mixins: [dialog],
  data() {
    return {
      formData: {
        id: null,
        // 学生个人信息
        userId: '', // 用户id
        stuNo: '', // 学号
        stuName: '', // 学生姓名
        examineNo: '', // 录取编号
        sexType: 1, // 性别（1——男，2——女）
        nation: '', // 民族
        political: '', // 政治面貌
        idNumber: '', // 身份证号
        idCardType: 1, // 证件类型
        fixedPhone: '', // 手机号码
        birthday: '', // 出生年月日
        userType: 2, // 用户类型1教职工,2学生,3后台管理员
        stuStatus: 0, // 学生状态（0——未注册，1——在读，2——毕业）
        middleSchool: '', // 中学名称
        // 录取信息
        examineCode: '', // 考生号
        graduateCategory: '', // 毕业类别
        admissionStage: '', // 录取阶段
        admissionBatch: '', // 录取批次
        categoryName: '', // 计划类别名称
        admissionCategory: '', // 录取类别
        admissionMark: '', // 录取总分
        subjectKind: '', // 科类名称
        // 专业信息
        campusId: '', // 校区
        collegeId: '', // 学院
        grade: new Date().getFullYear(), // 年级
        majorId: '', // 专业
        classesId: '', // 班级
        job: '', // 职务
        // 专升本意向信息
        bachelorIntentionSchool: '', // 专升本意向学校
        bachelorIntentionMajor: '', // 专升本意向专业
        // 联系信息
        linkMan: '', // 联系人
        linkManPhone: '', // 联系电话
        postCode: '', // 邮票
        addressDetails: '', // 详细地址
        remarks: '', // 备注
        payFlagYearOne: '',
        payFlagYearTwo: '',
        payFlagYearThree: ''
      },
      sexType: [],
      collegeList: [],
      majorList: [],
      campusList: [],
      gradeList: [], // 动态获取前后五年
      classesList: [],
      policyStatusList, // 政治面貌
      nationList, // 民族
      rules: {
        stuName: [
          {
            required: true,
            message: '请输入学生名称',
            trigger: 'blur'
          },
          this.$store.state.commonValidate.limit30WordsObj
        ],
        collegeId: {
          required: true,
          message: '请选择院系',
          trigger: 'blur'
        },
        grade: {
          required: true,
          message: '请选择年级',
          trigger: 'blur'
        },
        majorId: [{
          required: true,
          message: '请选择专业',
          trigger: 'blur'
        }],
        fixedPhone: this.$store.state.commonValidate.validatorPhoneObj,
        linkMan: [
          this.$store.state.commonValidate.limit30WordsObj],
        linkManPhone: this.$store.state.commonValidate.validatorPhoneObj,
        admissionMark: {
          validator: validateTwoDecimalPlaces,
          trigger: 'blur'
        },
        postCode: this.$store.state.commonValidate.validatorNumberObj,
        subjectKind: this.$store.state.commonValidate.limit30WordsObj,
        bachelorIntentionSchool: this.$store.state.commonValidate.limit30WordsObj,
        bachelorIntentionMajor: this.$store.state.commonValidate.limit30WordsObj,
        examineCode: this.$store.state.commonValidate.validatorNumberObj,
        graduateCategory: this.$store.state.commonValidate.limit30WordsObj,
        admissionStage: this.$store.state.commonValidate.limit30WordsObj,
        admissionBatch: this.$store.state.commonValidate.limit30WordsObj,
        categoryName: this.$store.state.commonValidate.limit30WordsObj,
        admissionCategory: this.$store.state.commonValidate.limit30WordsObj
      }
    }
  },
  mounted() {
    this.getGradeList()
    this.getPartTimeMajors()
    this.$nextTick(() => {
      if (this.id) {
        this.getDataById(this.id, getStudentPartTimeById).then(() => {
          // 身份证号为18号的时候，默认为身份证
          this.formData.idCardType = this.formData.idNumber.length === 18 ? 1 : 0
          this.setInitData()
        })
      } else {
        this.setInitData()
      }
    })
  },
  methods: {
    addStudentPartTime, // 保存api
    // 获取继续教育学院学生的专业列表
    getPartTimeMajors() {
      getPartTimeMajors().then(res => {
        this.majorList = res.data
      })
    },
    getGradeList() {
      // 默认往前十年
      const _date = new Date()
      const _year = _date.getFullYear() + 1
      const _yearArr = []
      for (let i = 0; i < 6; i++) {
        const item = _year - i
        _yearArr.push(item)
      }
      this.gradeList = _yearArr
    },
    handleIdType() {
      this.$nextTick(() => {
        this.$refs.form.clearValidate('idNumber')
        if (this.formData.idNumber) {
          this.$refs.form.validateField('idNumber')
        }
      })
    }
  }
}
</script>
