<template>
  <table-view :total="total" :query-info.sync="queryInfo" @reload-table="renderTable">
    <template #header>
      <view-search-form :queryInfo.sync="queryInfo" keyword-placeholder="姓名，身份证" :tool-list="['grade', 'keyword']"
                        @on-search="renderTable(1)">
        <el-form-item label="非全日制专业" label-width="120">
          <el-select v-model="queryInfo.majorId" clearable filterable size="small" style="width: 260px;">
            <el-option v-for="{ majorName, id } in partTimeMajorList" :key="id" :label="majorName" :value="id" />
          </el-select>
        </el-form-item>
      </view-search-form>
      <div class="header-button">
        <el-button type="success" plain size="small" @click="handleDownloadStuList()">导出自定义非全日制缴费</el-button>
        <template v-permission="['partTime:pay:add']">
          <upload-excel :permit-key="['partTime:pay:add']"
                        :loading.sync="loading"
                        :api="$http.importExcelHttp.importPartTimePayment"
                        @on-download="$http.exportExcelHttp.exportPartTimePaymentTemplate()"
                        @on-success="renderTable(1)" />
          <el-button type="primary" size="small" @click="showDialog()">添加自定义非全日制缴费
          </el-button>
        </template>
      </div>
    </template>
    <!-- body -->
    <el-table :data="tableData" style="width: 100%" border stripe>
      <el-table-column prop="orderNo" label="学费订单" width="160" align="center" />
      <el-table-column prop="payFlag" label="状态" width="90" align="center">
        <template v-slot="{ row }">
          <el-tag v-if="+row.payFlag" type="success">已缴</el-tag>
          <span class="info" v-else>-</span>
        </template>
      </el-table-column>
      <el-table-column prop="academicYear" label="缴费学年" width="100" align="center">
        <template v-slot="{ row }">
          第{{ chineseNum[+row.academicYear||0] }}学年
        </template>
      </el-table-column>
      <el-table-column prop="amount" label="缴费标准" width="100" />
      <el-table-column prop="collegeName" label="二级学院" width="120" />
      <el-table-column prop="majorName" label="专业名字" width="250" />
      <el-table-column prop="stuName" label="姓名" width="120" />
      <el-table-column prop="grade" label="年级" width="120" />
      <el-table-column prop="idNumber" label="身份证" width="180" />
      <el-table-column prop="examineCode" label="考生号" width="140" />
      <el-table-column prop="remarks" label="备注" min-width="150" />
      <el-table-column label="操作" fixed="right" width="150">
        <template v-slot="{ row }">
          <el-button v-permission="['partTime:pay:edit']" type="primary" size="mini"
                     @click="showDialog(row.id, `-${row.stuName}(${row.examineCode})`)">编辑
          </el-button>
          <el-button v-permission="['partTime:pay:del']" size="mini" type="danger"
                     @click="del(delPartTimePaymentByIdApi, row.id, `自定义非全日制缴费-${row.stuName}(考号:${row.examineCode})`)">
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <PartTimePaymentDialog ref="dialogRef" :visible.sync="visibleDialog" v-if="visibleDialog"
                           @on-close="renderTable" />
  </table-view>
</template>

<script>
import {
  getPartTimeMajors
} from '@/api/studentPartTime'
import tableView from '@/vue/mixins/table-view'
import { delPartTimePaymentByIdApi, pagePartTimePaymentApi } from '@/api/part-time/part-time-payment-api'
import PartTimePaymentDialog from '@/views/pages/stuInfoPartTime/part-time-payment/component/PartTimePaymentDialog.vue'

export default {
  name: 'PartTimePayment',
  components: {
    PartTimePaymentDialog
  },
  mixins: [tableView],
  data() {
    return {
      chineseNum: '零一二三四五六七',
      queryInfo: {
        grade: new Date().getFullYear(),
        majorId: ''
      },
      partTimeMajorList: []
    }
  },
  created() {
    this.getMajorList()
    this.renderTable(1)
  },
  methods: {
    delPartTimePaymentByIdApi, // 删除api
    // 获取学生列表
    renderTable(pageNum) {
      this.getTableData(pagePartTimePaymentApi, pageNum)
    },
    // 获取继续教育专业 list
    getMajorList() {
      getPartTimeMajors({}).then((res) => {
        this.partTimeMajorList = [
          {
            id: '',
            majorName: '所有专业'
          },
          ...res.data
        ]
      })
    },
    getTag(type) {
      if (type === 1) {
        return '<span class="el-tag el-tag--success el-tag--light">已缴</span>'
      } else {
        return '<span class="el-tag el-tag--info el-tag--light">未缴</span>'
      }
    },
    handleDownloadStuList() {
      this.$http.exportExcelHttp.downloadPartTimePayment(this.queryInfo, '自定义非全日制缴费').then()
    }
  }
}
</script>
