<template>
  <div>
    <el-dialog
      class="consultation-room-dialog"
      :title="`${preTitle}心理咨询教师`"
      :visible.sync="show"
      :close-on-click-modal="false"
      :before-close="cancel"
      width="720px"
    >
      <el-form ref="form" :model="formData" :rules="rules" label-width="100px">
        <template v-if="!id">
<!--          <el-form-item label="添加">-->
<!--            <el-radio-group v-model="addType">-->
<!--              <el-radio-button label="old">旧用户</el-radio-button>-->
<!--              <el-radio-button label="new">新用户</el-radio-button>-->
<!--            </el-radio-group>-->
<!--          </el-form-item>-->
          <el-form-item v-if="addType==='old'" label="搜索教师">
            <div class="flex">
              <el-autocomplete
                class="flex-1"
                :disabled="!!formData.userId"
                v-model="keyword"
                :fetch-suggestions="querySearchAsync"
                placeholder="请输入请输入教职工名"
                clearable
                @select="handleSelect" />
              <el-button @click="clearUserSelect">清空</el-button>
            </div>
          </el-form-item>
          <template v-else>
            <el-row>
              <el-col :span="12">
                <el-form-item label="姓名" prop="realName">
                  <el-input v-model.trim="formData.realName" @change="formData.name=$event" />
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="工号" prop="username">
                  <el-input v-model.trim="formData.username" />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item label="职工类别" prop="teacherType">
                  <el-select v-model="formData.teacherType" placeholder="请选择">
                    <el-option v-for="item in teacherTypeList" :key="item.paramValue" :label="item.paramValue"
                               :value="item.paramValue">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="入职时间" prop="entryTime">
                  <el-date-picker type="date" placeholder="请选择" v-model="formData.entryTime" style="width: 100%"
                                  value-format="yyyy-MM-dd" />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item label="部门">
                  <el-select v-model="formData.deptId" ref="deptRef" filterable clearable
                             @change="handleDeptId($event, 'dept')">
                    <el-option v-for="{ deptName, id } in departmentList" :label="deptName" :value="id" :key="id" />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="院系">
                  <el-select ref="collegeRef" v-model="formData.collegeId" filterable clearable
                             @change="handleDeptId($event, 'college')">
                    <el-option v-for="{ collegeName, id } in collegeList" :label="collegeName" :value="id" :key="id" />
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-form-item v-permission="['psycho:teacher:edit']" label="角色">
              <tool-tips-group is-flex
                               tips-content="<b>同一账号无法同时分配多个请假审批角色。</b><br/>请假审批角色包括：辅导员、学生处、副院长、校领导等，<br/>更多详情请到”系统设置-角色管理“模块查看或编辑。">
                <el-select ref="roleSelectRef" v-model="formData.roleIds" multiple filterable placeholder="请选择角色">
                  <el-option v-for="{ roleName, id } in roleList" :label="roleName" :value="id" :key="id" />
                </el-select>
              </tool-tips-group>
            </el-form-item>
            <el-form-item label="备注" prop="remarks">
              <el-input v-model.trim="formData.remarks" />
            </el-form-item>
            <el-divider content-position="left">用户信息</el-divider>
            <el-row>
              <el-col :span="12">
                <el-form-item label="用户照片" prop="realImg">
                  <single-img-uploader img-fit="contain" :url.sync="formData.realImg" height="164px" />
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="性别" prop="sexType">
                  <el-radio-group v-model="formData.sexType" placeholder="请选择性别">
                    <el-radio v-for="{ name, type } in $store.state.systemParam.sexType" :label="type" :key="type">
                      {{ name
                      }}
                    </el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item label="身份证号码" prop="cardNum">
                  <el-input v-model.trim="formData.cardNum" />
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="手机号码" prop="phone">
                  <el-input v-model.trim="formData.phone"  maxlength="11"/>
                </el-form-item>
              </el-col>
            </el-row>
          </template>
        </template>
        <el-divider content-position="left">心理咨询师信息</el-divider>
        <el-row>
          <el-form-item v-if="!!id" label="咨询师姓名" prop="name">
            <el-input v-model.trim="formData.name" />
          </el-form-item>
          <el-col :span="11">
            <el-form-item label="咨询师照片" prop="headImg">
              <single-img-uploader img-fit="contain" :url.sync="formData.headImg" height="164px" />
            </el-form-item>
          </el-col>
          <el-col :span="13">
            <el-form-item label="职位" prop="post">
              <div class="flex">
                <el-select class="flex-1" v-model="formData.post" clearable>
                  <el-option v-for="{ paramName, id } in postList" :label="paramName" :value="paramName" :key="id" />
                </el-select>
                <el-button class="ml-1" type="text" icon="el-icon-edit" @click="visibleDialog=true" />
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="状态" prop="offFlag">
              <el-switch
                v-model="formData.offFlag"
                active-color="var(--color-success)"
                :active-value="0"
                active-text="在岗"
                inactive-text="休假"
                :inactive-value="1"
              />
            </el-form-item>
          </el-col>
        </el-row>

        <el-form-item label="简介" prop="intro">
          <el-input type="textarea" rows="2" v-model.trim="formData.intro" />
        </el-form-item>
      </el-form>
      <template #footer>
        <cancel-popover ref="cancelRef" :disabled.sync="loadData.loading" :update-flag="updateFlag" />
        <el-button type="primary" @click="handleSave">确 定</el-button>
      </template>
    </el-dialog>
    <!--  职位dialog  -->
    <PostDialog append-to-body :visible.sync="visibleDialog" v-if="visibleDialog" @close="getPostList" />
  </div>
</template>

<script>
import dialog from '@/vue/mixins/dialog'
import { validateIdNumber, validatePhone } from '@/utils/form-validate'
import { getParamsList, getSysDictionaryByKey } from '@/api/sysParams'
import { listRoleApi } from '@/api/system/role-api'
import CampusSchMajorClasses from '@/vue/mixins/campusSchMajorClasses'
import PostDialog from '@/views/pages/studentManage/psychology-teacher/components/PostDialog.vue'
import { throttle } from 'lodash'
import { addUser, getUserList } from '@/api/system/user-api'
import { getPsychologyTeacherByIdApi, savePsychologyTeacherApi } from '@/api/student-manage/psychology-teacher-api'

export default {
  name: 'psychologyTeacherDialog',
  components: { PostDialog },
  mixins: [dialog, CampusSchMajorClasses],
  props: {
    addData: {
      type: Object
    }
  },
  data() {
    return {
      addType: 'new', // 老用户
      keyword: '', // 远程搜索
      visibleDialog: false,
      campusList: [], // 校区list
      formData: {
        id: '',
        //   以下为 心理咨询教师 接口用的参数
        headImg: '', // app显示的头像
        userId: '',
        intro: '',
        name: '',
        offFlag: 0, // 0-在岗， 1-休假
        post: '' // 心理教师的职位，跟其他模块区别开
      },
      collegeList: [],
      postList: [], // 职位list
      roleList: [], // 全部角色列表
      departmentList: [],
      teacherTypeList: [], // 职工类型列表
      rules: {
        realName: [{
          required: true,
          message: '请输入姓名',
          trigger: 'blur'
        },
          this.$store.state.commonValidate.limit20WordsObj],
        teacherType: [{
          required: true,
          message: '请选择职工类别',
          trigger: 'blur'
        }],
        cardNum: [
          {
            required: true,
            message: '请输入身份证号',
            trigger: 'blur'
          },
          {
            validator: validateIdNumber,
            trigger: 'blur'
          }],
        phone: [
          {
            required: true,
            message: '请输入手机号',
            trigger: 'blur'
          },
          {
            validator: validatePhone,
            trigger: 'blur'
          }
        ],
        username: [
          {
            required: true,
            message: '请输入用户名',
            trigger: 'blur'
          },
          {
            min: 2,
            max: 20,
            message: '长度在 2 到 20 个字符',
            trigger: 'blur'
          }
        ]
      }
    }
  },
  async mounted() {
    await Promise.all([
      this.getCollegeList(),
      this.getPostList(),
      this.getDepartmentList(),
      this.getRoleList(),
      this.getTeacherType()
    ])
    this.$nextTick(async () => {
      if (this.id) {
        await this.getDataById(this.id, getPsychologyTeacherByIdApi)
      } else {
        this.formData = {
          // 以下为人事管理、后台用户新增用到的参数
          id: '',
          roleType: 1, // 1- 教师账号 带上教职工的角色 2-其他，不带教职工的角色
          realName: '', // 姓名
          username: '', // 账号
          password: '',
          phone: '',
          cardNum: '',
          remarks: '',
          userType: 3, // 用户类型1-教职工,2-学生,3-教师,一般固定
          teacherType: '',
          entryTime: '', // 入职时间
          collegeId: this.$store.state.login.userInfo.collegeId || '',
          deptId: '',
          realImg: '',
          sexType: null,
          roleIds: [],
          psychologyFlag: 1, // 是否心理教师 1-是
          //   以下为 心理咨询教师 接口用的参数
          headImg: '', // app显示的头像
          userId: '',
          intro: '',
          name: '',
          offFlag: 0, // 0-在岗， 1-休假
          post: '' // 心理教师的职位，跟其他模块区别开
        }
        this.setInitData()
      }
    })
  },
  methods: {
    // 获取部门
    async getDepartmentList() {
      try {
        const res = await this.$http.getDepartmentListQuery({})
        this.departmentList = res.data
      } catch (e) {
      }
    },
    // 获取职工类型
    async getTeacherType() {
      try {
        const res = await getParamsList({ keyword: '教师类型' })
        this.teacherTypeList = res.data.list
      } catch (e) {
        console.error(e)
      }
    },
    // 获取全部角色列表
    async getRoleList() {
      try {
        const res = await listRoleApi()
        this.roleList = res.data
      } catch (e) {
      }
    },
    // 获取全部职位列表
    async getPostList() {
      try {
        const { data } = await getSysDictionaryByKey('psychoPost')
        this.postList = data || []
      } catch (e) {
      }
    },
    // 根据 获取的学院自动填写 部门，或者根据部门获取学院
    handleDeptId(val, type) {
      this.$nextTick(() => {
        if (val) {
          switch (type) {
            case 'college':
              this.formData.deptId = this.departmentList.find(({ deptName }) => this.$refs.collegeRef.selectedLabel === deptName)?.id
              break
            case 'dept':
              this.formData.collegeId = this.collegeList.find(({ collegeName }) => this.$refs.deptRef.selectedLabel === collegeName)?.id
              break
          }
        }
      })
    },
    // 远程搜索 教职工名
    async querySearchAsync(keyword, cb) {
      let _result = []
      /* 判断是否请求接口 为false 时请求接口
      * 默认为 true ,因为为false+keyword===''时加载所有的几万条教职工数据
      * */
      let _correctQuery = false
      if (keyword) {
        if (!isNaN(keyword)) {
          // 支持身份证号(number)搜索,但是学号/考号/身份证号必须填入6位数以上,减轻服务器压力
          if (keyword.length > 5) {
            _correctQuery = true
          }
        } else if (keyword.length > 1) {
          // 为string,且关键字大于一个字才能开始搜索,减轻服务器压力
          _correctQuery = true
        }
      }
      if (_correctQuery) {
        // 只筛选 未成为心理教师的教职工
        const _query = {
          keyword: keyword,
          pageNum: 1,
          userType: 3,
          psychologyFlag: 0,
          pageSize: 1000
        }
        try {
          const res = await getUserList(_query)
          res.data.list.map((item) => {
            Object.assign(item, { value: item.realName })
          })
          _result = res.data.list
        } catch (e) {
        } finally {
          cb(_result)
        }
      } else {
        this.formData.userId = null
        Object.keys(this.userInfo).forEach(key => {
          if (key !== 'value') {
            this.userInfo[key] = null
          }
        })
        cb(_result)
      }
    },
    // 获取选中的教职工信息
    handleSelect(teac) {
      this.formData.userId = teac.id
      this.formData.realName = teac.realName
      this.formData.username = teac.username
      this.formData.teacherType = teac.teacherType
    },
    // 清空选中
    clearUserSelect() {
      this.formData.userId = ''
      this.formData.realName = ''
      this.formData.username = ''
      this.formData.teacherType = ''
    },
    handleSave: throttle(function async() {
      if (this.loadData.loading) {
        this.$message.error('请勿重复提交!')
        return
      }
      // 判断表单是否有更新
      this.compareData()
      if (!this.updateFlag) {
        this.$message.error('表单未被修改!')
        return
      }
      this.$refs.form.validate((valid) => {
        if (!valid) {
          this.$message('未通过校验,可能是漏填必填项!')
          return false
        }
        const _editType = this.preTitle ? this.preTitle : (this.id ? '编辑' : '新增')
        this.$confirm(`此操作将${_editType}心理咨询师（教师信息会自动同步到人事管理模块）, 是否继续?`, '提示', {
          dangerouslyUseHTMLString: true
        }).then(async () => {
          this.loadData.loading = true
          try {
            if (this.id) {
              // 编辑时
              await savePsychologyTeacherApi(this.formData)
            } else {
              // 新增逻辑，要请求两个接口
              // 新增后 获取返回的data 作为userId存到心理咨询师
              // 如果是通过远程搜索添加教师时，直接赋值userId，否则需要请求user新增接口获取userId
              // if (this.formData.userId) this.form.id = this.formData.userId
              const { data } = await addUser(this.formData)
              // if (!this.formData.userId) {
              //   this.formData.userId = data
              // }
              const _formData = {
                headImg: this.formData.headImg,
                userId: data,
                intro: this.formData.intro,
                name: this.formData.realName,
                offFlag: this.formData.offFlag,
                post: this.formData.post
              }
              await savePsychologyTeacherApi(_formData)
            }
            this.$message.success(`${_editType}心理咨询师成功`)
            this.closed()
          } catch (e) {
          } finally {
            this.loadData.loading = false
          }
        }).catch()
      })
    }, 1000)
  }
}
</script>
