<template>
  <el-dialog
    class="part-time-student-dialog"
    :title="`${preTitle}继续教育学院学生${title}`"
    :visible.sync="show"
    :close-on-click-modal="false"
    width="620px"
    :before-close="cancel">
    <el-form ref="form" :model="formData" :rules="rules" label-width="100px">
      <el-row>
        <el-form-item label="姓名" prop="stuName">
          <el-input v-model.trim="formData.stuName" />
        </el-form-item>
        <el-form-item label="证件类型" prop="idCardType">
          <el-radio-group v-model="formData.idCardType" @change="handleIdType">
            <el-radio-button :label="1">内地身份证</el-radio-button>
            <el-radio-button :label="0">港澳身份证</el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="身份证号" prop="idNumber">
          <el-input v-model.trim="formData.idNumber" maxlength="18" />
        </el-form-item>
        <el-form-item label="考生号" prop="examineCode">
          <el-input v-model.trim="formData.examineCode" maxlength="15" />
        </el-form-item>
        <el-form-item label="二级学院" prop="collegeName">
          <el-input v-model.trim="formData.collegeName" maxlength="15" />
        </el-form-item>
        <el-form-item label="专业" prop="majorName">
          <el-input v-model.trim="formData.majorName" maxlength="15" />
          <!--          <el-select v-model="formData.majorName" filterable>-->
          <!--            <el-option-->
          <!--              v-for="{majorName,id} in majorList"-->
          <!--              :label="majorName"-->
          <!--              :value="id"-->
          <!--              :key="id" />-->
          <!--          </el-select>-->
        </el-form-item>
        <el-form-item label="缴费标准" prop="amount">
          <el-input v-model.trim="formData.amount" maxlength="8" />
        </el-form-item>
        <el-form-item label="缴费学年" prop="academicYear">
          <el-select v-model="formData.academicYear">
            <el-option
              v-for="num in 3"
              :label="`第${chineseNum[+num]}学年`"
              :value="num"
              :key="num" />
          </el-select>
        </el-form-item>
        <el-form-item label="年级" prop="grade">
          <el-select v-model="formData.grade">
            <el-option
              v-for="year in gradeList"
              :label="year"
              :value="year"
              :key="year" />
          </el-select>
        </el-form-item>
      </el-row>
      <el-form-item label="备注" prop="remarks">
        <el-input v-model.trim="formData.remarks" />
      </el-form-item>
    </el-form>
    <template #footer>
      <cancel-popover ref="cancelRef" :disabled.sync="loadData.loading" :update-flag="updateFlag" />
      <el-button type="primary" @click="save('全日制学生',savePartTimePaymentApi)">确 定</el-button>
    </template>
  </el-dialog>
</template>

<script>
import {
  policyStatusList,
  nationList
} from '@/views/pages/diagnosis-and-improvement/infoCollect/infoCollect/components/options.js'
import { getPartTimeMajors } from '@/api/studentPartTime'
import dialog from '@/vue/mixins/dialog'
import { validateTwoDecimalPlaces } from '@/utils/form-validate'
import { getPartTimePaymentByIdApi, savePartTimePaymentApi } from '@/api/part-time/part-time-payment-api'
import { getGradeList } from '@/api/grade'

export default {
  name: 'PartTimePaymentDialog',
  mixins: [dialog],
  data() {
    return {
      chineseNum: '零一二三四五六七',
      formData: {
        id: null,
        // 学生个人信息
        userId: '', // 用户id
        stuName: '', // 学生姓名
        idNumber: '', // 身份证号
        idCardType: 1, // 证件类型
        amount: null, // 缴费标准
        collegeName: '',
        majorName: '',
        // 录取信息
        examineCode: '', // 考生号
        grade: new Date().getFullYear(), // 年级
        remarks: '' // 备注
      },
      collegeList: [],
      majorList: [],
      schoolYearList: [],
      gradeList: [], // 动态获取前后五年
      classesList: [],
      policyStatusList, // 政治面貌
      nationList, // 民族
      rules: {
        stuName: [
          {
            required: true,
            message: '请输入学生名称',
            trigger: 'blur'
          },
          this.$store.state.commonValidate.limit30WordsObj
        ],
        amount: [
          {
            required: true,
            message: '请输入缴费标准',
            trigger: 'blur'
          },
          {
            validator: validateTwoDecimalPlaces,
            trigger: 'blur'
          }
        ],
        academicYear: {
          required: true,
          message: '请选择缴费学年',
          trigger: 'blur'
        },
        collegeName: [{
          required: true,
          message: '请输入二级学院',
          trigger: 'blur'
        }],
        majorName: [{
          required: true,
          message: '请输入专业',
          trigger: 'blur'
        }],
        linkMan: [this.$store.state.commonValidate.limit30WordsObj],
        linkManPhone: this.$store.state.commonValidate.validatorPhoneObj,
        admissionMark: {
          validator: validateTwoDecimalPlaces,
          trigger: 'blur'
        },
        examineCode: [{
          required: true,
          message: '请选择考生号',
          trigger: 'blur'
        }, this.$store.state.commonValidate.validatorNumberObj]
      }
    }
  },
  async mounted() {
    await Promise.all([
      this.getGradeYearList(),
      this.handleGradeList(),
      this.getPartTimeMajors()
    ])
    this.$nextTick(() => {
      if (this.id) {
        this.getDataById(this.id, getPartTimePaymentByIdApi).then(() => {
          // 身份证号为18号的时候，默认为身份证
          this.formData.idCardType = this.formData.idNumber.length === 18 ? 1 : 0
          this.setInitData()
        })
      } else {
        this.setInitData()
      }
    })
  },
  methods: {
    savePartTimePaymentApi, // 保存api
    // 获取继续教育学院学生的专业列表
    getPartTimeMajors() {
      getPartTimeMajors().then(res => {
        this.majorList = res.data
      })
    },
    handleGradeList() {
      // 默认往前十年
      const _date = new Date()
      const _year = _date.getFullYear() + 1
      const _yearArr = []
      for (let i = 0; i < 6; i++) {
        const item = _year - i
        _yearArr.push(item)
      }
      this.gradeList = _yearArr
    },
    // 获取学年
    async getGradeYearList() {
      try {
        const { data } = await getGradeList({})
        this.schoolYearList = data.list
      } catch (e) {
      }
    },
    handleIdType() {
      this.$nextTick(() => {
        this.$refs.form.clearValidate('idNumber')
        if (this.formData.idNumber) {
          this.$refs.form.validateField('idNumber')
        }
      })
    }
  }
}
</script>
