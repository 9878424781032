import { exportExcel, importExcel, exportWord } from '@/service/excelHttp.js'

const host = process.env.VUE_APP_API_URL

/* 导出excel */
export const exportExcelHttp = {
  // 用户导入模板
  exportUserTemplate: (params) => {
    return exportExcel(host, '/sys/user/downloadModel', 'post', params, '后台用户导入模板')
  },
  // hr 的 用户导入模板
  exportUserByHRTemplate: (params) => {
    return exportExcel(host, '/sys/user/downloadModelByHr', 'post', params, '用户导入模板(人事处)')
  },
  // 学生导入模板
  exportStudentTemplate: (params) => {
    return exportExcel(host, '/stu/stuInfo/downloadModel', 'post', params, '学生导入模板')
  },
  // 自定义非全日制缴费 导入模板
  exportPartTimePaymentTemplate: (params) => {
    return exportExcel(host, '/stu/stuInfoPartTimeCustom/downloadModel', 'post', params, '自定义非全日制缴费模板')
  },
  // 录取通知书导入模板
  exportAdmissionLetterTemplate: (params) => {
    return exportExcel(host, '/stu/admission/downloadModel', 'post', params, '录取通知书导入模板')
  },
  // 院系导入模板
  exportCollegeTemplate: (params) => {
    return exportExcel(host, '/sch/college/downloadModel', 'post', params, '院系导入模板')
  },
  // 专业导入模板
  exportMajorTemplate: (params) => {
    return exportExcel(host, '/sch/major/downloadModel', 'post', params, '专业导入模板')
  },
  // 专业导出
  exportMajorTransferInfo: (params) => {
    return exportExcel(host, '/stu/stuInfo/exportStuChangeMajor', 'post', params, '学生专业调整数据')
  },
  // 导出转专业记录
  exportMajorTransferRecord: (params) => {
    return exportExcel(host, '/stu/stuMajorChange/exportChangeMajor', 'post', params, '学生专业调整记录')
  },
  // 班级导入模板
  exportClassesTemplate: (params) => {
    return exportExcel(host, '/sch/classes/downloadModel', 'post', params, '班级导入模板')
  },
  // 自定义缴费/专升本缴费导入模板
  exportStuPayTypeTemplate: (params) => {
    return exportExcel(host, '/stu/customPay/downloadBindStuModel', 'post', params, '批量绑定学生导入模板')
  },
  // 宿舍房间导入模板
  exportDormRoomTemplate: (params) => {
    return exportExcel(host, '/dormitory/dormitoryRoom/downloadModel', 'post', params, '宿舍房间导入模板')
  },
  // 宿舍床位导入模板
  exportDormRoomBedTemplate: (params) => {
    return exportExcel(host, '/dormitory/dormitoryBed/downloadModel', 'post', params, '宿舍床位导入模板')
  },
  // 教师导入模板
  exportTeacherTemplate: (params) => {
    return exportExcel(host, '/stu/teacherInfo/downloadModel', 'post', params, 'APP教师用户模板')
  },
  // 宿舍楼栋导入模板
  exportDormBuildTemplate: (params) => {
    return exportExcel(host, '/dormitory/dormitoryInfo/downloadModel', 'post', params, '宿舍楼栋导入模板')
  },
  // 学费缴费登记导入模板
  exportStudentOrderTemplate: (params) => {
    return exportExcel(host, '/stu/stuTuitionOrder/downloadModel', 'post', params, '缴费登记导入模板')
  },
  // 教学楼导入模板
  exportTeachBuildTemplate: (params) => {
    return exportExcel(host, '/app/teachBuild/downloadModel', 'post', params, '教学楼导入模板')
  },
  // 课室导入模板
  exportTeachRoomTemplate: (params) => {
    return exportExcel(host, '/app/teachClassroom/downloadModel', 'post', params, '课室导入模板')
  },
  // 门锁导入模板
  exportLockTemplate: (params) => {
    return exportExcel(host, '/app/teachRoom/downloadModel', 'post', params, '门锁导入模板')
  },
  // 导出入住花名册
  exportDormRoomBedInfo: (params) => {
    return exportExcel(host, '/dormitory/dormitoryRoom/exportDormitoryMsg', 'post', params, '入住花名册')
  },
  // 导出学生信息
  exportStudentInfo: (params, filename = '学生信息') => {
    return exportExcel(host, '/stu/stuInfo/exportStu', 'post', params, filename)
  },
  // 导出录取通知书信息
  exportAdmissionLetter: (params, filename = '录取通知信息') => {
    return exportExcel(host, '/stu/admission/export', 'post', params, filename)
  },
  // 导出年度费用导入模板
  exportPriceTemplate: (params) => {
    return exportExcel(host, '/sch/price/downloadModel', 'post', params, '年度费用导入模板')
  },
  // 老生导入模板
  exportOldStudentTemplate: (params) => {
    return exportExcel(host, '/stu/stuInfo/downloadOldStuModel', 'post', params, '老生导入模板')
  },
  // 部门导入模板
  exportDepartmentTemplate: (params) => {
    return exportExcel(host, '/sch/dept/downloadModel', 'post', params, '部门导入模板')
  },
  // 导出学年缴费名单
  exportStudentPayStatus: (params, filename = '缴费名单') => {
    return exportExcel(host, '/stu/stuTuitionOrder/exportStu', 'post', params, filename)
  },
  // 导出支付订单
  exportPayOrder: (params, filename = '支付订单') => {
    return exportExcel(host, '/pay/payOrder/export', 'post', params, filename)
  },
  // 导出统一学费订单
  exportUniPayOrder: (params, filename = '学费订单') => {
    return exportExcel(host, '/pay/payOrder/exportUni', 'post', params, filename)
  },
  // 导出宿舍数据
  exportDormitoryRoom: (params, filename = '宿舍数据') => {
    return exportExcel(host, '/dormitory/dormitoryRoom/exportDormitoryRoom', 'post', params, filename)
  },
  // 新生宿舍统计
  exportMajorAndDormitoryStatus: (params, filename = '新生宿舍统计') => {
    return exportExcel(host, '/dormitory/dormitoryRoom/exportMajorAndDormitoryStatus', 'post', params, filename)
  },
  // 报到统计数据
  exportCountStuStatus: (params, filename = '报到统计数据') => {
    return exportExcel(host, '/stu/stuInfo/exportCountStuStatus', 'post', params, filename)
  },
  // 报到统计数据
  exportStudentPartTimeTemplate: (params, filename = '非全日制学生导入模板') => {
    return exportExcel(host, '/stu/stuInfoPartTime/downloadModel', 'post', params, filename)
  },
  // 报到统计数据
  exportStudentPartTimeUpdateTemplate: (params, filename = '非全日制学生批量更新模板') => {
    return exportExcel(host, '/stu/stuInfoPartTime/downloadPartTimeUpdate', 'post', params, filename)
  },
  // 非全日制学生缴费情况名单
  downloadPartTimeStu: (params, filename = '非全日制学生缴费情况名单') => {
    return exportExcel(host, '/stu/stuInfoPartTime/downloadPartTimeStu', 'post', params, filename)
  },
  // 非全日制学生名单
  downloadPartTimeStuList: (params, filename = '非全日制学生名单') => {
    return exportExcel(host, '/stu/stuInfoPartTime/export', 'post', params, filename)
  },
  // 导出 自定义非全日制缴费名单
  downloadPartTimePayment: (params, filename = '自定义非全日制缴费') => {
    return exportExcel(host, '/stu/stuInfoPartTimeCustom/export', 'post', params, filename)
  },
  // 导出学生
  exportStudentActivityInfo: (params, filename = '活动报名表') => {
    return exportExcel(host, '/app/activity/exportJoinRecord', 'post', params, filename)
  },
  // 导出用户
  exportUser: (params, filename = '用户导出表') => {
    return exportExcel(host, '/sys/user/export', 'post', params, filename)
  },
  // hr 导出用户
  exportUserByHR: (params, filename = '用户导出表(人事处)') => {
    return exportExcel(host, '/sys/user/exportHr', 'post', params, filename)
  },
  // 导出维修记录
  exportRepair: (params, filename = '维修记录表') => {
    return exportExcel(host, '/dormitory/dormitoryRepair/export', 'post', params, filename)
  },
  // 导出单个请假记录 格式为 zip
  exportSingleLeaveOut: (id, filename = '请假单') => {
    return exportExcel(host, `/stu/stuLeave/export/${id}`, 'post', {}, filename, '.zip')
  },
  // 批量导出多个请假记录 格式为 zip
  exportMultipleLeaveOut: (params, filename = '请假单') => {
    return exportExcel(host, '/stu/stuLeave/exportBatch', 'post', params, filename, '.zip')
  },
  // 批量导出部门诊断信息
  exportRectification: (params, filename = '部门诊断信息') => {
    return exportExcel(host, '/teacher/teacherTermDeptCorrect/export', 'post', params, filename)
  },
  // 批量导出竞赛成果
  exportCompetition: (params, filename = '竞赛成果') => {
    return exportExcel(host, '/teacher/teacherTermGuidedCompetitionAwards/export', 'post', params, filename)
  },
  // 批量导出专利成果
  exportPatent: (params, filename = '专利成果') => {
    return exportExcel(host, '/teacher/teacherTermPatent/export', 'post', params, filename)
  },
  // 批量导出课题成果
  exportSubject: (params, filename = '课题成果') => {
    return exportExcel(host, '/teacher/teacherTermProjectResult/export', 'post', params, filename)
  },
  // 批量导出论文成果
  exportThesis: (params, filename = '论文成果') => {
    return exportExcel(host, '/teacher/teacherTermPaperResult/export', 'post', params, filename)
  },
  // 批量导出带照片学生信息
  exportStudentFiles: (params, filename = '学生信息（带照片）') => {
    return exportExcel(host, '/teacher/teacherTermPaperResult/export', 'post', params, filename, '.zip')
  },
  // 批量导出带照片用户信息
  exportUserFiles: (id, filename = '用户信息（带照片）') => {
    return exportExcel(host, `/sys/asyncExcelFile/download/${id}`, 'get', {}, filename, '.zip')
  },
  // 导出年度费用
  exportYearPrice: (params, filename = '年度费用') => {
    return exportExcel(host, '/sch/price/export', 'post', params, filename)
  },
  // 导出专业费用
  exportMajorCampus: (params, filename = '专业管理') => {
    return exportExcel(host, '/sch/major/exportMajorCampus', 'post', params, filename)
  }
}

/* 导入excel */
export const importExcelHttp = {
  // HR 导入用户
  importUserByHR: (params) => {
    return importExcel(host, '/sys/user/importHr', 'post', params)
  },
  // 导入用户
  importUser: (params) => {
    return importExcel(host, '/sys/user/importSysUser', 'post', params)
  },
  // 导入学生
  importStudent: (params) => {
    return importExcel(host, '/stu/stuInfo/importStuInfo', 'post', params)
  },
  // 导入录取通知书
  importAdmissionLetter: (params) => {
    return importExcel(host, '/stu/admission/import', 'post', params)
  },
  // 自定义缴费/专升本缴费导入绑定学生
  importPayModelBindStudent: (params, batchId) => {
    return importExcel(host, `/stu/customPay/batch/bindStuByExcel/${batchId}`, 'post', params)
  },
  // 导入院系
  importCollege: (params) => {
    return importExcel(host, '/sch/college/importCollege', 'post', params)
  },
  // 导入专业
  importMajor: (params) => {
    return importExcel(host, '/sch/major/importMajor', 'post', params)
  },
  // 导入班级
  importClasses: (params) => {
    return importExcel(host, '/sch/classes/importClasses', 'post', params)
  },
  // 导入宿舍房间
  importDormRoom: (params) => {
    return importExcel(host, '/dormitory/dormitoryRoom/importDormitoryRoom', 'post', params)
  },
  // 导入宿舍床位
  importDormRoomBed: (params) => {
    return importExcel(host, '/dormitory/dormitoryBed/importDormitoryBed', 'post', params)
  },
  // 导入教师
  importTeacher: (params) => {
    return importExcel(host, '/stu/teacherInfo/importTeacherInfo', 'post', params)
  },
  // 导入宿舍楼栋
  importDormBuild: (params) => {
    return importExcel(host, '/dormitory/dormitoryInfo/importDormitory', 'post', params)
  },
  // 导入学费缴费登记
  importStudentOrder: (params) => {
    return importExcel(host, '/stu/stuTuitionOrder/importStuTuitionOrder', 'post', params)
  },
  // 导入年度费用
  importPrice: (params) => {
    return importExcel(host, '/sch/price/importPrice', 'post', params)
  },
  // 导入老生
  importOldStudent: (params) => {
    return importExcel(host, '/stu/stuInfo/importOldStuInfo', 'post', params)
  },
  // 导入部门
  importDepartment: (params) => {
    return importExcel(host, '/sch/dept/importDept', 'post', params)
  },
  // 导入教学楼
  importTeachBuild: (params) => {
    return importExcel(host, '/app/teachBuild/importRoom', 'post', params)
  },
  // 导入教室
  importClassroom: (params) => {
    return importExcel(host, '/app/teachClassroom/importClassroom', 'post', params)
  },
  // 导入门锁
  importLock: (params) => {
    return importExcel(host, '/app/teachRoom/importRoom', 'post', params)
  },
  // 导入非全日制学生
  importStudentPartTime: (params) => {
    return importExcel(host, '/stu/stuInfoPartTime/importStuInfo', 'post', params)
  },
  // 导入自定义非全日制缴费
  importPartTimePayment: (params) => {
    return importExcel(host, '/stu/stuInfoPartTimeCustom/importStuInfo', 'post', params)
  },
  // 导入非全日制学生更新
  importUpdateStudentPartTime: (params) => {
    return importExcel(host, '/stu/stuInfoPartTime/importStuInfo2Update', 'post', params)
  }

}

/* 导出word */
export const exportWordHttp = {
  // 导出校长信箱
  exportPrincipalMailBox: (id, filename = '校长信箱') => {
    return exportWord(host, `/app/sysFeedback/exportSysFeedbackWord/${id}`, 'post', {}, filename)
  }
}
