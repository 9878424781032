/* 招生办 */
export const recruitManageRouter = [
  {
    path: '/recruitManage/importStudent',
    name: 'importStudent',
    component: () => import('@/views/pages/recruitManage/import-student/importStudent.vue'),
    meta: {
      title: '学生名单录入',
      role: ['超级管理员']
    }
  },
  {
    path: '/recruitManage/admissionLetter',
    name: 'AdmissionLetter',
    component: () => import('@/views/pages/recruitManage/admission-letter/AdmissionLetter.vue'),
    meta: {
      title: '录取通知书管理',
      role: ['超级管理员']
    }
  },
  {
    path: '/recruitManage/paidStudent',
    name: 'paidStudent',
    component: () => import('@/views/pages/recruitManage/paidStudent.vue'),
    meta: {
      title: '已缴费未报到名单',
      role: ['超级管理员']
    }
  },
  {
    path: '/recruitManage/noReportStudent',
    name: 'noReportStudent',
    component: () => import('@/views/pages/recruitManage/noReportStudent.vue'),
    meta: {
      title: '未报道名单',
      role: ['超级管理员']
    }
  },
  {
    path: '/recruitManage/reportCount',
    name: 'reportCount',
    component: () => import('@/views/pages/recruitManage/reportCount.vue'),
    meta: {
      title: '报道统计',
      role: ['超级管理员']
    }
  },
  {
    path: '/recruitManage/educationImp',
    name: 'educationImp',
    component: () => import('@/views/pages/recruitManage/educationImp.vue'),
    meta: {
      title: '专升本管理',
      role: ['超级管理员']
    }
  },
  {
    path: '/recruitManage/educationImpPay',
    name: 'educationImpPay',
    component: () => import('@/views/pages/financeManage/batch-pay-model/batchPayModel.vue'),
    meta: {
      title: '专升本缴费',
      role: ['超级管理员']
    }
  },
  {
    path: '/recruitManage/higherSchoolCount',
    name: 'higherSchoolCount',
    component: () => import('@/views/pages/recruitManage/higherSchoolCount.vue'),
    meta: {
      title: '专升本意向统计',
      role: ['超级管理员']
    }
  },
  {
    path: '/recruitManage/checkInPhoto',
    name: 'checkInPhoto',
    component: () => import('@/views/pages/recruitManage/check-in-photo/checkInPhoto.vue'),
    meta: {
      title: '报到照片录入',
      role: ['超级管理员']
    }
  }
]
