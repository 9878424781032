<template>
  <div class="addTeacher-dialog">
    <base-dialog
      :model="addData.isOpen"
      @close="closeDialogHandle"
      :title="addData.title"
    >
      <template #body>
        <el-form
          ref="form"
          :model="formData"
          :rules="rules"
          label-width="100px"
        >
          <el-form-item label="教师名称" prop="teacherName">
            <el-input v-model.trim="formData.teacherName"></el-input>
          </el-form-item>
          <el-form-item label="教师类型">
            <el-select v-model="formData.teacherType">
              <span v-for="(item, index) in teacherType" :key="index">
                <el-option
                  :label="item.paramName"
                  :value="Number(item.paramValue)"
                  :key="index"
                ></el-option>
              </span>
            </el-select>
          </el-form-item>
          <el-form-item label="性别">
            <el-select v-model="formData.sexType">
              <span v-for="(item, index) in sexType" :key="index">
                <el-option
                  :label="item.name"
                  :value="item.type"
                  :key="index"
                ></el-option>
              </span>
            </el-select>
          </el-form-item>
          <el-form-item label="手机号码" prop="phone">
            <el-input type="number" v-model.trim="formData.phone" maxlength="11"></el-input>
          </el-form-item>
          <el-form-item label="所在校区" prop="campusId">
            <el-select v-model="formData.campusId" @change="changeCampus">
                        <span v-for="(item, index) in campusList" :key="index">
                          <el-option
                            :label="item.campusName"
                            :value="item.id"
                            :key="index"
                          ></el-option>
                        </span>
            </el-select>
          </el-form-item>
          <el-form-item label="使用扫码功能">
            <el-select v-model="formData.functions" multiple placeholder="请选择">
              <el-option
                :key="1"
                label="报到"
                value="1">
              </el-option>
              <el-option
                :key="2"
                label="校服"
                value="2">
              </el-option>
              <el-option
                :key="3"
                label="校园卡"
                value="3">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="备注" prop="remarks">
            <el-input v-model.trim="formData.remarks"></el-input>
          </el-form-item>
        </el-form>
      </template>
      <template #footer>
        <el-button @click="addData.isOpen = false">取 消</el-button>
        <el-button type="primary" @click="addTeacherHandle">确 定</el-button>
      </template>
    </base-dialog>
  </div>
</template>

<script>
import { addTeacher, getTeacherById } from '@/api/teacher'

export default {
  props: {
    addData: {
      type: Object
    }
  },
  data () {
    return {
      formData: {
        campusId: '', // 校区
        deptId: '', // 部门
        collegeId: '', // 院系id
        teacherName: '', // 姓名
        teacherType: 2, // 教师类型
        sexType: 1, // 1——男，2——女
        phone: '', // 手机号码
        userId: '', // 用户id
        functions: '',
        remarks: ''
      },
      campusList: [],
      departmentList: [],
      collegeList: [],
      sexType: [],
      teacherType: [],
      rules: {
        teacherName: [
          {
            required: true,
            message: '请输入教师名称',
            trigger: 'blur'
          }
        ],
        deptId: [{
          required: true,
          message: '请选择部门',
          trigger: 'blur'
        }],
        phone: [{
          required: true,
          message: '请填写手机号码',
          trigger: 'blur'
        }],
        collegeId: [{
          required: true,
          message: '请选择院系',
          trigger: 'blur'
        }],
        campusId: [{
          required: true,
          message: '请选择校区',
          trigger: 'blur'
        }]
      }
    }
  },
  watch: {
    'addData.isOpen': function (isOpen) {
      if (isOpen) {
        this.sexType = this.$store.state.systemParam.sexType
        this.teacherType = this.$store.state.systemParam.teacherType
        this.getCampusList()
      }
      if (isOpen && this.addData.type === 'edit') {
        this.getTeacherById()
      }
      if (!isOpen) {
        this.resetFormData()
      }
    }
  },
  methods: {
    // 根据id获取教师信息
    getTeacherById () {
      getTeacherById(this.addData.id).then((res) => {
        Object.keys(this.formData).forEach((item) => {
          this.formData[item] = res.data[item]
        })
        this.formData.id = this.addData.id
        this.formData.functions = this.formData.functions.split(',')
        this.getDepartmentList(this.formData.campusId)
        this.getCollegeList(this.formData.campusId)
      })
    },
    // 获取校区
    getCampusList () {
      this.$http.getCampusListQuery({}).then((res) => {
        this.campusList = res.data
      })
    },
    // 获取部门
    getDepartmentList (campusId) {
      this.$http.getDepartmentListQuery({ campusId }).then((res) => {
        this.departmentList = res.data
      })
    },
    // 获取院系
    getCollegeList (campusId) {
      this.$http.getCollegeListQuery({ campusId }).then((res) => {
        this.collegeList = res.data
      })
    },
    // 选择校区
    changeCampus (campusId) {
      this.formData.deptId = ''
      this.formData.collegeId = ''
      this.getDepartmentList(campusId)
      this.getCollegeList(campusId)
    },
    // 添加教师
    addTeacherHandle () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.formData.functions = this.formData.functions.join(',')
          addTeacher(this.formData).then((res) => {
            this.$refs.form.resetFields()
            this.$message({
              message: '添加成功',
              type: 'success'
            })
            this.$emit('successHandle')
            this.closeDialogHandle()
          })
        }
      })
    },
    // 重置搜索表单
    resetFormData () {
      this.formData = {
        campusId: '', // 校区
        deptId: '', // 部门
        collegeId: '', // 院系id
        teacherName: '', // 姓名
        teacherType: 1, // 教师类型
        sexType: 1, // 1——男，2——女
        phone: '', // 手机号码
        functions: '', // 扫码功能
        userId: '', // 用户id
        remarks: ''
      }
    },
    // 关闭弹窗
    closeDialogHandle () {
      this.addData.isOpen = false
    }
  }
}
</script>
<style lang="scss" scoped>
</style>
