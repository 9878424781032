<template>
  <el-dialog
    class="qui-time-dialog"
    :title="`编辑${titleMap[key]}`"
    :visible.sync="show"
    :close-on-click-modal="false"
    width="460px"
    :before-close="cancel">
    <el-form ref="form" :model="formData" :rules="rules" label-position="top">
      <el-form-item  :label="titleMap[key]" prop="paramValue">
        <!--      修改截止时间  -->
        <el-date-picker v-if="key==='admissionLetterQuitEditTime'" type="datetime" v-model.trim="formData.paramValue"
                        format="yyyy-MM-dd HH:mm"
                        value-format="yyyy-MM-dd HH:mm"
                        date-format="yyyy-MM-dd"
                        time-format="HH:mm" />
        <!--    修改登录页提示    -->
        <el-input v-if="key==='admissionLetterTips'" maxlength="400" show-word-limit type="textarea" rows="4" v-model="formData.paramValue" />
      </el-form-item>
    </el-form>
    <template #footer>
      <cancel-popover ref="cancelRef" :disabled.sync="loadData.loading" :update-flag="updateFlag" />
      <el-button type="primary" @click="save(titleMap[key],addParams)">确 定</el-button>
    </template>
  </el-dialog>
</template>

<script>
import dialog from '@/vue/mixins/dialog'
import { getSysParamsByKey, addParams } from '@/api/sysParams'

export default {
  name: 'ParamsDialog',
  mixins: [dialog],
  data() {
    return {
      titleMap: {
        admissionLetterQuitEditTime: '收件信息修改截至时间',
        admissionLetterTips: '登录查询H5登录页提示'
      },
      key: '', // 要修改的系统参数
      formData: {
      },
      rules: {
        paramValue: {
          required: true,
          message: '该项为必填',
          trigger: 'blur'
        }
      }
    }
  },
  methods: {
    addParams, // 保存api
    // 邮寄信息不需要修改学生信息，因此分开两个对象接收（readData，formData）
    async getData(key) {
      try {
        const { data } = await getSysParamsByKey(key)
        this.formData = data
        this.id = data.id
        this.setInitData()
      } catch (e) {
        console.error(e)
      }
    }
  }
}
</script>
