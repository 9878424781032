<template>
  <el-dialog
    class="admission-letter-dialog"
    :title="`${preTitle}录取通知书`"
    :visible.sync="show"
    :close-on-click-modal="false"
    width="860px"
    :before-close="cancel">
    <el-form ref="form" :model="formData" :rules="rules" label-width="120px">
    <el-divider content-position="left"><h3>录取个人信息:</h3></el-divider>
      <el-row>
        <el-col :span="12">
          <el-form-item label="录取学生" prop="stuName">
            <el-input v-model.trim="formData.stuName" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="考生号" prop="examineCode">
            <el-input v-model.trim="formData.examineCode" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="录取院系" prop="collegeName">
            <el-input v-model.trim="formData.collegeName" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="录取专业" prop="majorName">
            <el-input v-model.trim="formData.majorName" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="录取阶段" prop="admissionStage">
            <el-input v-model.trim="formData.admissionStage" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="身份证号" prop="idNumber">
            <el-input v-model.trim="formData.idNumber"  maxlength="18"/>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="年级" prop="grade">
            <el-select v-model="formData.grade" filterable>
              <el-option v-for="{ paramValue, id } in gradeList" :key="id" :label="paramValue" :value="Number(paramValue)" />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-divider content-position="left"><h3>快递收件信息</h3></el-divider>
      <el-row>
        <el-col :span="12">
          <el-form-item label="收件人" prop="addressee">
            <el-input v-model.trim="formData.addressee" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="收件手机号" prop="addresseePhone">
            <el-input v-model.trim="formData.addresseePhone" maxlength="11"/>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="收件邮政编码" prop="addresseePostCode">
            <el-input v-model.trim="formData.addresseePostCode" maxlength="6"/>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="快递单号" prop="admissionExpressNumber">
            <el-input v-model.trim="formData.admissionExpressNumber" />
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="邮寄地址" prop="admissionMailAddress">
            <el-input v-model.trim="formData.admissionMailAddress" />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <template #footer>
      <cancel-popover ref="cancelRef" :disabled.sync="loadData.loading" :update-flag="updateFlag" />
      <el-button type="primary" @click="save('录取通知书信息', saveAdmissionLetterApi)">确 定</el-button>
    </template>
  </el-dialog>
</template>

<script>
import { getAdmissionLetterById, saveAdmissionLetterApi } from '@/api/admission-letter-api'
import dialog from '@/vue/mixins/dialog'
import { validate50WordLimit, validateNumber, validatePhone } from '@/utils/form-validate'
import campusSchMajorClasses from '@/vue/mixins/campusSchMajorClasses'

export default {
  name: 'AdmissionLetterDialog',
  mixins: [dialog, campusSchMajorClasses],
  data() {
    return {
      gradeList: this.$store.state.systemParam.grade, // 年级list
      formData: {
        id: '',
        stuName: '',
        examineCode: '',
        admissionStage: '',
        grade: '',
        idNumber: '',
        collegeName: '',
        majorName: '',
        addressee: '',
        addresseePhone: '',
        addresseePostCode: '',
        admissionMailAddress: '',
        admissionExpressNumber: ''
      },
      rules: {
        grade: { required: true, message: '该项为必填', trigger: 'blur' },
        examineCode: [
          {
            required: true,
            message: '该项为必填',
            trigger: 'blur'
          },
          this.$store.state.commonValidate.validatorNumberObj
        ],
        admissionStage: [
          {
            required: true,
            message: '该项为必填',
            trigger: 'blur'
          },
          this.$store.state.commonValidate.limit30WordsObj
        ],
        majorName: [
          {
            required: true,
            message: '该项为必填',
            trigger: 'blur'
          },
          this.$store.state.commonValidate.limit30WordsObj
        ],
        collegeName: [
          {
            required: true,
            message: '该项为必填',
            trigger: 'blur'
          },
          this.$store.state.commonValidate.limit30WordsObj
        ],
        stuName: [
          {
            required: true,
            message: '该项为必填',
            trigger: 'blur'
          },
          this.$store.state.commonValidate.limit30WordsObj
        ],
        addressee: [
          {
            required: true,
            message: '该项为必填',
            trigger: 'blur'
          },
          this.$store.state.commonValidate.limit30WordsObj
        ],
        admissionExpressNumber: [{
          required: true,
          message: '该项为必填',
          trigger: 'blur'
        },
          validateNumber],
        admissionMailAddress: [{
          required: true,
          message: '该项为必填',
          trigger: 'blur'
        },
          validate50WordLimit],
        addresseePhone: [
          {
            required: true,
            message: '请填写联系人手机号码',
            trigger: 'blur'
          },
          {
            validator: validatePhone,
            trigger: 'blur'
          }
        ],
        addresseePostCode: [{
          validator: validateNumber,
          trigger: 'blur'
        }]
      }
    }
  },
  async mounted() {
    await this.$nextTick()
    if (this.id) {
      await this.getDataById(this.id, getAdmissionLetterById)
    } else {
      this.setInitData()
    }
  },
  methods: {
    // 保存api
    saveAdmissionLetterApi
  }
}
</script>
<style lang="scss">
.admission-letter-dialog {
  .el-dialog {
    margin-top: 20px !important;
  }
}
</style>
