<!--  学生问诊记录  -->
<template>
  <table-view :total="total" :query-info.sync="queryInfo" @reload-table="renderTable">
    <template #header>
      <!--   去掉学院筛选条件，否则全局的 限制教师只能按照所属学院筛选信息的问题会出现   -->
      <view-search-form ref="searchFormRef" :queryInfo.sync="queryInfo" :tool-list="['class', 'major', 'keyword']"
        @on-search="renderTable(1)">
        <el-form-item label="咨询室" label-width="60px">
          <el-select v-model="queryInfo.psychologyRoomId" clearable size="small">
            <el-option v-for="{ name, id } in roomList" :key="id" :label="name" :value="id" />
          </el-select>
        </el-form-item>
        <el-form-item label="状态" label-width="50px">
          <el-select v-model="queryInfo.status" clearable size="small">
            <el-option v-for="{ paramValue, paramName } in statusList" :key="paramValue" :label="paramName"
              :value="+paramValue" />
          </el-select>
        </el-form-item>
        <el-form-item v-if="isAdmin" label="心理咨询教师" label-width="100px">
          <el-select v-model="queryInfo.userId" clearable size="small">
            <el-option v-for="{ name, userId } in teacherList" :key="userId" :label="name" :value="userId" />
          </el-select>
        </el-form-item>
        <el-form-item label="咨询日期">
          <el-date-picker v-model="queryInfo.day" type="date" size="small" placeholder="咨询日期" value-format="yyyy-MM-dd" />
        </el-form-item>
      </view-search-form>
    </template>
    <el-table :data="tableData" style="width: 100%" border stripe>
      <el-table-column prop="day" label="日期" width="160">
        <template v-slot="{ row }">
          {{ row.day }}(周{{ row.weekDayName }})
        </template>
      </el-table-column>
      <el-table-column prop="classHourName" label="课时" width="120" />
      <el-table-column label="来访人" width="160">
        <template v-slot="{ row }">
          <!--          <template v-if="$store.state.login.userInfo.id===row.teaUserId"> {{ row.stuName }}</template>-->
          <el-button type="text" @click="showDialog(row.id, row, 'view')">
            {{ row.userType == 2 ? row.stuName : row.orderTeaName }}
          </el-button>
        </template>
      </el-table-column>
      <el-table-column prop="majorName" label="专业" min-width="200" />
      <el-table-column prop="collegeName" label="学院" min-width="200" />
      <el-table-column prop="orderTeaDeptName" label="院系/部门" min-width="200" />
      <el-table-column prop="fixedPhone" label="来访人电话" min-width="160">
        <template v-slot="{ row }">
          {{ row.userType == 2 ? row.fixedPhone : row.orderTeaPhone }}
        </template>
      </el-table-column>
      <el-table-column label="咨询室" min-width="200">
        <template v-slot="{ row }">
          {{ row.roomName }}({{ row.campusName }})
        </template>
      </el-table-column>
      <el-table-column prop="teaName" label="值班教师" width="120" />
      <el-table-column label="咨询状态" min-width="100" fixed="right" align="center">
        <template v-slot="{ row }">
          {{ statusList.length && (statusList.find(item => +item.paramValue === +row.status).paramName) }}
        </template>
      </el-table-column>
      <el-table-column prop="remarks" label="备忘" min-width="200" />
      <el-table-column label="操作" width="170" align="center" fixed="right">
        <template v-slot="{ row }">
          <template v-if="isAdmin || ($store.state.login.userInfo.id === row.teaUserId)">
            <el-button v-if="$store.state.login.userInfo.id === row.teaUserId" type="primary" plain size="mini"
              @click="showDialog(row.id, row, 'edit')">修改状态
            </el-button>
            <el-button type="danger"
              @click="del(delPsychoStuRecordByIdApi, row.id, `心理咨询记录-${row.userType == 2 ? row.stuName : row.orderTeaName}(${row.day} ${row.classHourName})`)">删除
            </el-button>
          </template>
        </template>
      </el-table-column>
    </el-table>
    <psycho-stu-record-dialog ref="dialogRef" :visible.sync="visibleDialog" v-if="visibleDialog" :status-list="statusList"
      @on-close="renderTable" />
  </table-view>
</template>

<script>
import tableView from '@/vue/mixins/table-view'
import {
  pagePsychoStuRecordApi,
  delPsychoStuRecordByIdApi
} from '@/api/student-manage/psychology-stu-record-api'
import { listPsychologyRoomApi } from '@/api/student-manage/psychology-room-api'
import { listPsychologyTeacherApi } from '@/api/student-manage/psychology-teacher-api'
import { getSysDictionaryByKey } from '@/api/sysParams'
import PsychoStuRecordDialog
  from '@/views/pages/studentManage/psychology-stu-record/components/psychoStuRecordDialog.vue'

export default {
  name: 'psychologyStuRecord',
  components: { PsychoStuRecordDialog },
  mixins: [tableView],
  data() {
    return {
      isAdmin: false, // 查看所有记录的权限 管理员
      isTeacher: false, // 教师，只能查看负责的学生的记录
      visibleScheduleDialog: false,
      queryInfo: {
        collegeId: null,
        majorId: null,
        classesId: null,
        offFlag: null,
        campusId: null,
        psychologyRoomId: null,
        status: null,
        day: null,
        userId: null
      },
      gradeYearList: [],
      roomList: [], // 咨询室list
      teacherList: [], // 咨询教师list
      statusList: [] // 咨询状态list
    }
  },
  async mounted() {
    this.isAdmin = this.$store.state.login.userInfo.paraNameList.includes('psycho:app')
    await Promise.all([
      this.getRoomList(),
      this.getTeacherList(),
      this.getStatusList()
    ])
    // await this.renderTable(1)
  },
  methods: {
    delPsychoStuRecordByIdApi, // 删除api
    async renderTable(pageNum) {
      const _userInfo = this.$store.state.login.userInfo
      this.queryInfo.userId = this.isAdmin ? this.queryInfo.userId : _userInfo.id
      await this.getTableData(pagePsychoStuRecordApi, pageNum)
    },
    // 咨询室list
    async getRoomList() {
      try {
        const res = await listPsychologyRoomApi()
        this.roomList = res.data
      } catch (e) {
        console.error(e)
      }
    },
    // 咨询教师list
    async getTeacherList() {
      try {
        const res = await listPsychologyTeacherApi()
        this.teacherList = res.data
      } catch (e) {
        console.error(e)
      }
    },
    // 问诊状态 list
    async getStatusList() {
      try {
        const res = await getSysDictionaryByKey('psychoRecordStatus')
        this.statusList = res.data
      } catch (e) {
        console.error(e)
      }
    },
    /*
    * 显示弹窗
    * visibleDialog {boolean} 控制弹窗显示/隐藏
    * id {string} 一般为row的id,编辑状态要传
    * preTitle {string} 用于弹窗标题
    * title {string} 用于弹窗标题后缀
    * view=查看， edit= 编辑  add=新增
     */
    showDialog(id = '', row, type) {
      if (!row) row = ''
      this.visibleDialog = true
      this.$nextTick(() => {
        this.$refs.dialogRef.type = type
        this.$refs.dialogRef.title = row
        if (type === 'view') {
          this.$refs.dialogRef.preTitle = '查看'
        } else if (type === 'edit') {
          this.$refs.dialogRef.preTitle = '修改'
          Object.keys(this.$refs.dialogRef.formData).forEach(key => {
            this.$refs.dialogRef.formData[key] = row[key]
          })
        }
        this.$refs.dialogRef.id = id
        // 深拷贝原始formData
        this.$refs.dialogRef.setInitData()
      })
    }
  }
}
</script>
