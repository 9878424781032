/* 宿管中心 */
export const stuInfoPartTimeRouter = [
  {
    path: '/stuInfoPartTime/list',
    name: 'stuInfoPartTime',
    component: () => import('@/views/pages/stuInfoPartTime/student-list/list.vue'),
    meta: {
      title: '非全日制学生',
      role: ['超级管理员']
    }
  },
  {
    path: '/stuInfoPartTime/partTimePayment',
    name: 'PartTimePayment',
    component: () => import('@/views/pages/stuInfoPartTime/part-time-payment/PartTimePayment.vue'),
    meta: {
      title: '自定义非全日制缴费',
      role: ['超级管理员']
    }
  },
  {
    path: '/stuInfoPartTime/payOrder',
    name: 'payOrder',
    component: () => import('@/views/pages/stuInfoPartTime/pay-order/payOrder.vue'),
    meta: {
      title: '支付订单',
      role: ['超级管理员']
    }
  },
  {
    path: '/stuInfoPartTime/pattimeStuPayCount',
    name: 'pattimeStuPayCount',
    component: () => import('@/views/pages/stuInfoPartTime/part-time-stu-pay-count/pattimeStuPayCount.vue'),
    meta: {
      title: '支付统计',
      role: ['超级管理员']
    }
  }
]
