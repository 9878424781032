var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('table-view',{attrs:{"total":_vm.total,"query-info":_vm.queryInfo},on:{"update:queryInfo":function($event){_vm.queryInfo=$event},"update:query-info":function($event){_vm.queryInfo=$event},"reload-table":_vm.renderTable},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('view-search-form',{ref:"searchFormRef",attrs:{"queryInfo":_vm.queryInfo,"tool-list":['class', 'major', 'keyword']},on:{"update:queryInfo":function($event){_vm.queryInfo=$event},"update:query-info":function($event){_vm.queryInfo=$event},"on-search":function($event){return _vm.renderTable(1)}}},[_c('el-form-item',{attrs:{"label":"咨询室","label-width":"60px"}},[_c('el-select',{attrs:{"clearable":"","size":"small"},model:{value:(_vm.queryInfo.psychologyRoomId),callback:function ($$v) {_vm.$set(_vm.queryInfo, "psychologyRoomId", $$v)},expression:"queryInfo.psychologyRoomId"}},_vm._l((_vm.roomList),function(ref){
var name = ref.name;
var id = ref.id;
return _c('el-option',{key:id,attrs:{"label":name,"value":id}})}),1)],1),_c('el-form-item',{attrs:{"label":"状态","label-width":"50px"}},[_c('el-select',{attrs:{"clearable":"","size":"small"},model:{value:(_vm.queryInfo.status),callback:function ($$v) {_vm.$set(_vm.queryInfo, "status", $$v)},expression:"queryInfo.status"}},_vm._l((_vm.statusList),function(ref){
var paramValue = ref.paramValue;
var paramName = ref.paramName;
return _c('el-option',{key:paramValue,attrs:{"label":paramName,"value":+paramValue}})}),1)],1),(_vm.isAdmin)?_c('el-form-item',{attrs:{"label":"心理咨询教师","label-width":"100px"}},[_c('el-select',{attrs:{"clearable":"","size":"small"},model:{value:(_vm.queryInfo.userId),callback:function ($$v) {_vm.$set(_vm.queryInfo, "userId", $$v)},expression:"queryInfo.userId"}},_vm._l((_vm.teacherList),function(ref){
var name = ref.name;
var userId = ref.userId;
return _c('el-option',{key:userId,attrs:{"label":name,"value":userId}})}),1)],1):_vm._e(),_c('el-form-item',{attrs:{"label":"咨询日期"}},[_c('el-date-picker',{attrs:{"type":"date","size":"small","placeholder":"咨询日期","value-format":"yyyy-MM-dd"},model:{value:(_vm.queryInfo.day),callback:function ($$v) {_vm.$set(_vm.queryInfo, "day", $$v)},expression:"queryInfo.day"}})],1)],1)]},proxy:true}])},[_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.tableData,"border":"","stripe":""}},[_c('el-table-column',{attrs:{"prop":"day","label":"日期","width":"160"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.day)+"(周"+_vm._s(row.weekDayName)+") ")]}}])}),_c('el-table-column',{attrs:{"prop":"classHourName","label":"课时","width":"120"}}),_c('el-table-column',{attrs:{"label":"来访人","width":"160"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.showDialog(row.id, row, 'view')}}},[_vm._v(" "+_vm._s(row.userType == 2 ? row.stuName : row.orderTeaName)+" ")])]}}])}),_c('el-table-column',{attrs:{"prop":"majorName","label":"专业","min-width":"200"}}),_c('el-table-column',{attrs:{"prop":"collegeName","label":"学院","min-width":"200"}}),_c('el-table-column',{attrs:{"prop":"orderTeaDeptName","label":"院系/部门","min-width":"200"}}),_c('el-table-column',{attrs:{"prop":"fixedPhone","label":"来访人电话","min-width":"160"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.userType == 2 ? row.fixedPhone : row.orderTeaPhone)+" ")]}}])}),_c('el-table-column',{attrs:{"label":"咨询室","min-width":"200"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.roomName)+"("+_vm._s(row.campusName)+") ")]}}])}),_c('el-table-column',{attrs:{"prop":"teaName","label":"值班教师","width":"120"}}),_c('el-table-column',{attrs:{"label":"咨询状态","min-width":"100","fixed":"right","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.statusList.length && (_vm.statusList.find(function (item) { return +item.paramValue === +row.status; }).paramName))+" ")]}}])}),_c('el-table-column',{attrs:{"prop":"remarks","label":"备忘","min-width":"200"}}),_c('el-table-column',{attrs:{"label":"操作","width":"170","align":"center","fixed":"right"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(_vm.isAdmin || (_vm.$store.state.login.userInfo.id === row.teaUserId))?[(_vm.$store.state.login.userInfo.id === row.teaUserId)?_c('el-button',{attrs:{"type":"primary","plain":"","size":"mini"},on:{"click":function($event){return _vm.showDialog(row.id, row, 'edit')}}},[_vm._v("修改状态 ")]):_vm._e(),_c('el-button',{attrs:{"type":"danger"},on:{"click":function($event){_vm.del(_vm.delPsychoStuRecordByIdApi, row.id, ("心理咨询记录-" + (row.userType == 2 ? row.stuName : row.orderTeaName) + "(" + (row.day) + " " + (row.classHourName) + ")"))}}},[_vm._v("删除 ")])]:_vm._e()]}}])})],1),(_vm.visibleDialog)?_c('psycho-stu-record-dialog',{ref:"dialogRef",attrs:{"visible":_vm.visibleDialog,"status-list":_vm.statusList},on:{"update:visible":function($event){_vm.visibleDialog=$event},"on-close":_vm.renderTable}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }