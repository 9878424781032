import { render, staticRenderFns } from "./addTeacher.vue?vue&type=template&id=3ab52744&scoped=true&"
import script from "./addTeacher.vue?vue&type=script&lang=js&"
export * from "./addTeacher.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3ab52744",
  null
  
)

export default component.exports